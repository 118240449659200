import {environment} from '../../environments/environment';


export class AppConfig {

  public static BACKEND_API_BASE_URL = environment.API_BASE_URL;
  public static BACKEND_API_AUTH_URL = environment.API_AUTH_URL;
  public static BACKEND_API_TENANT_CONFIG = environment.API_TENANT_CONFIG_URL;
  public static GOOGLE_MAPS_API_KEY = 'AIzaSyB4w0Jd1rAVU3XjQSGPGRPA2vYvwX9KL1E';
  public static DEFAULT_USER_IMAGE = '/assets/images/profiles/user-profile.png';

  public static BASE64_TAG_JPEG = 'data:image/jpeg;base64,';
  public static TENANT_REGEX = '^([A-Z]{2}_TENANT_\\d+)$';
  public static PATTERN_COUNTRY_CODE = 'countryCode';
  public static SECURITY_GROUP_REGEX = '^G_';
}

export enum NAVIGATION {
  HOME = 'login',
  ERROR = 'error',
  LANDING = 'landing',
  DASHBOARD = 'dashboard',
  PHASE_SELECTOR = 'phase-selector',
  BRIDGE = 'bridge',
  SUBCONTRACTOR = 'subcontractor',
  USER = 'user',
  REPORT = 'report',
  ADMIN_MONITOR = 'admin-monitor',
  ABOUT_US = 'about-us',
  ADMIN_PANEL = 'admin-panel',
  APP_SETTINGS = 'app-settings',
  NOT_CONFIGURED = '**'
}

export enum SECURITY_GROUPS {
  G_ADMIN = 'G_ADMIN',
  G_SUPER_ADMIN = 'G_SUPER_ADMIN',
  G_SECONDARY_USER = 'G_SECONDARY_USER',
  G_EXTERNAL_USER = 'G_EXTERNAL_USER',
  G_SYSENSOR_ADMIN = 'G_SYSENSOR_ADMIN',
  G_MOBILE_USER = 'G_MOBILE_USER'
}

export enum SESSION_STORAGE_KEYS {
  BRIDGE_STATUS = 'bridgeStatus',
  UNIT_NAME_LIST = 'unitNameList',
  STAGE_DESCRIPTION_LIST = 'stageDescriptionList',
  UI_COMPONENTS = 'uiComponents',
  SELECTED_COUNTRY = 'selectedCountry',
  SELECTED_COUNTRY_CODE = 'selectedCountryCode',
  SECURITY_CONTEXT_EXCHANGE_FOR_LOGGED_USER = 'securityContextExchangeForLoggedUser',
  BRIDGE_ISSUE_TYPE_LIST = 'bridgeIssueTypeList'

}

export enum LOCAL_STORAGE_KEYS {
  APP_INFO = 'appInfo',
}
